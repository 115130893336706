import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'bp/layout/layout.component';
import { AuthGuard } from 'projects/bp-admin/src/bp/@core/services/is-login.guard';
import { AcceptedRiskGuard } from 'projects/bp-admin/src/bp/shared/guards/accepted-risk.guard';
import { BpAuthGuard } from 'projects/bp-core/src/lib/services/portal/is-login.guard';
// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const bpAppRoutes: Route[] = [
  // Redirect TO dashboard
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

  // NO guard - NO sidebar - NO bpstepsheader
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      // Auth
      {
        path: 'auth',
        loadChildren: () => import('bp/modules/auth/auth.module').then(mod => mod.AuthModule),
      },

      // Maintenance
      {
        path: 'maintenance',
        loadChildren: () =>
          import('bp/modules/maintenance/maintenance.module').then(m => m.MaintenanceModule),
      },
    ],
  },

  // SÍ guard - si sidebar - NO bpstepsheader
  {
    path: '',
    canActivate: [BpAuthGuard, AcceptedRiskGuard],
    component: LayoutComponent,
    data: {
      layout: 'bp',
    },
    resolve: {
      initialData: initialDataResolver,
    },
    // canActivateChild: [AuthGuard],
    children: [
      //Dashboard
      {
        path: 'audit-events',
        loadChildren: () =>
          import('./modules/audit-events/audit-events.module').then(m => m.AuditEventsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'log-externals',
        loadChildren: () =>
          import('./modules/log-external/log-external.module').then(m => m.LogExternalModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'users-new',
        loadChildren: () => import('./modules/users-new/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'referral',
        loadChildren: () =>
          import('./modules/referral/referral.module').then(m => m.ReferralModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'bp-us',
        loadChildren: () => import('./modules/bp-us/bp-us.module').then(m => m.ReferralModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'questions',
        loadChildren: () =>
          import('./modules/questions/questions.module').then(m => m.QuestionsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'plan-calculations',
        loadChildren: () =>
          import('./modules/plan-calculation/plan-calculation.module').then(
            m => m.PlanCalculationModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'goal-categories',
        loadChildren: () =>
          import('./modules/goal-category/goal-category.module').then(m => m.GoalCategoryModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'risk-profiles',
        loadChildren: () =>
          import('./modules/risk-profiles/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'financial-entities',
        loadChildren: () =>
          import('./modules/financial-entities/financial-entities.module').then(
            m => m.FinancialEntitiesModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'portfolios',
        loadChildren: () =>
          import('./modules/portfolio/portfolio.module').then(m => m.PortfolioModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'communes',
        loadChildren: () =>
          import('./modules/communes/communes.module').then(m => m.CommunesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'regions',
        loadChildren: () => import('./modules/regions/regions.module').then(m => m.RegionsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'funds',
        loadChildren: () => import('./modules/funding/funding.module').then(m => m.FundingModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'funding-share-values',
        loadChildren: () =>
          import('./modules/funding-share-value/funding-share-value.module').then(
            m => m.FundingShareValueModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'composition-category',
        loadChildren: () =>
          import('./modules/composition-category/composition-category.module').then(
            m => m.CompositionCategoryModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'group-composition-category',
        loadChildren: () =>
          import('./modules/group-composition-category/group-composition-category.module').then(
            m => m.GroupCompositionCategoryModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'params',
        loadChildren: () => import('./modules/params/params.module').then(m => m.ParamsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'box-transactions',
        loadChildren: () =>
          import('./modules/box-transactions/box-transactions.module').then(
            m => m.BoxTransactionsModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'contracts',
        loadChildren: () =>
          import('./modules/contracts/contracts.module').then(m => m.ContractsModule),
        canActivate: [AuthGuard],
      },
      // ECONSULT
      {
        path: 'econsult',
        loadChildren: () =>
          import('./modules/econsult/econsult.module').then(m => m.EconsultModule),
        canActivate: [AuthGuard],
      },
      // - ECONSULT

      // VECTOR
      {
        path: 'vector',
        loadChildren: () => import('./modules/vector/vector.module').then(m => m.VectorModule),
        canActivate: [AuthGuard],
      },
      // - VECTOR

      // BCI
      {
        path: 'bci',
        loadChildren: () => import('./modules/bci/bci.module').then(m => m.BciModule),
        canActivate: [AuthGuard],
      },
      // - BCI

      {
        path: 'spread-ranges',
        loadChildren: () =>
          import('./modules/spread-ranges/spread-ranges.module').then(m => m.SpreadRangesModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'config',
        loadChildren: () => import('./modules/config/config.module').then(m => m.ConfigModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'validation-ci',
        loadChildren: () =>
          import('./modules/validation-ci/validation-ci.module').then(m => m.ValidationCiModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'jobs',
        loadChildren: () =>
          import('./modules/jobs-vnext/jobs-vnext.module').then(m => m.JobsVnextModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'risk-levels',
        loadChildren: () =>
          import('./modules/risk-levels/risk-levels.module').then(m => m.RiskLevelsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'working-hours',
        loadChildren: () =>
          import('./modules/working-hours/working-hours.module').then(m => m.WorkingHoursModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'advisor-vacations',
        loadChildren: () =>
          import('./modules/advisor-vacations/advisor-vacations.module').then(
            m => m.AdvisorVacationsModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'holidays',
        loadChildren: () =>
          import('./modules/holidays/holidays.module').then(m => m.HolidaysModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'transactions',
        loadChildren: () =>
          import('./modules/transactions/transactions.module').then(m => m.TransactionsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'transactions-funds',
        loadChildren: () =>
          import('./modules/goal-transaction-funding/goal-transaction-funding.module').then(
            m => m.GoalTransactionFundingModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'time-horizon',
        loadChildren: () =>
          import('./modules/time-horizon/time-horizon.module').then(m => m.TimeHorizonModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'portfolio-calculation',
        loadChildren: () =>
          import('./modules/portfolio-calculation/portfolio-calculation.module').then(
            m => m.PortfolioCalculationModule,
          ),
      },
      {
        path: 'circularizations',
        loadChildren: () =>
          import('./modules/circularization/circularization.module').then(
            m => m.CircularizationModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'risk-profile-responses',
        loadChildren: () =>
          import('./modules/risk-profile-responses/risk-profile-responses.module').then(
            m => m.RiskProfileResponsesModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'investment-strategy',
        loadChildren: () =>
          import('./modules/investment-strategy/investment-strategy.module').then(
            m => m.InvestmentStrategyModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'investment-strategy-type',
        loadChildren: () =>
          import('./modules/investment-strategy-type/investment-strategy-type.module').then(
            m => m.InvestmentStrategyTypeModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'bank-transactions',
        loadChildren: () =>
          import('./modules/bank-transaction/bank-transaction.module').then(
            m => m.BankTransactionModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'advisor-assignment-rules',
        loadChildren: () =>
          import('./modules/advisor-assignment-rule/advisor-assignment-rule.module').then(
            m => m.AdvisorAssignmentRuleModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'currency',
        loadChildren: () =>
          import('./modules/currency/currency.module').then(m => m.CurrencyModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'roles',
        loadChildren: () => import('./modules/role/role.module').then(m => m.RoleModule),
      },
      {
        path: 'transaction-requests',
        loadChildren: () =>
          import('./modules/transaction-requests/transaction-requests.module').then(
            m => m.TransactionRequestsModule,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'external',
        loadChildren: () =>
          import('./modules/external/external.module').then(m => m.ExternalModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'internal-notification-event-configuration',
        loadChildren: () =>
          import(
            './modules/internal-notification-event-configuration/internal-notification-event-configuration.module'
          ).then(m => m.InternalNotificationEventConfigurationModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'templates',
        loadChildren: () =>
          import('./modules/templates/templates.module').then(m => m.TemplatesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'products',
        loadComponent: () =>
          import('./modules/products/products.component').then(m => m.ProductsComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'dps-conditions',
        loadComponent: () =>
          import('./modules/products/dps-conditions/dps-conditions.component').then(
            m => m.DpsConditionsComponent,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'product-types',
        loadComponent: () =>
          import('./modules/product-types/product-types.component').then(
            m => m.ProductTypesComponent,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'product-plans',
        loadComponent: () =>
          import('./modules/product-plans/product-plans.component').then(
            m => m.ProductPlansComponent,
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'potencial-plan',
        loadChildren: () =>
          import('./modules/potencial-plan/potencial-plan.routes').then(m => m.routes),
      },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    ],
  },
];

export const notMatchRedirectRoutes: Route[] = [
  {
    path: 'redirect-to',
    pathMatch: 'full',
    loadChildren: () =>
      import('bp/modules/redirect/redirect.module').then(mod => mod.RedirectModule),
  },
  { path: '**', redirectTo: 'redirect-to' },
];

export const notMatchRedirectRoutesOld: Route[] = [
  {
    path: 'not-found',
    pathMatch: 'full',
    loadChildren: () => import('bp/modules/error-404/error-404.routes'),
  },
  { path: '**', redirectTo: 'not-found' },
];

//{ path: '**', redirectTo: 'redirect-to', pathMatch: 'full' },
//{ path: '**', redirectTo: '404-not-found' },
