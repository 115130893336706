import { lumberjackLogDriverConfigToken, lumberjackLogDriverToken } from '@ngworker/lumberjack';
import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable } from '@angular/core';
const lumberjackConsoleDriverConfigToken = new InjectionToken('__LUMBERJACK_CONSOLE_DRIVER_CONFIG__');

/**
 * Wraps the browser console abstraction used by the console driver to make it
 * overridable in tests or add additional information to console logs.
 */
const lumberjackConsoleToken = new InjectionToken('__LUMBERJACK_CONSOLE__', {
  factory: () => console,
  providedIn: 'root'
});

/**
 * The console driver outputs logs to the browser console.
 *
 * It forwards the formatted log and the optional log payload to the relevant
 * method of the browser console API.
 */
class LumberjackConsoleDriver {
  static driverIdentifier = 'LumberjackConsoleDriver';
  #console = inject(lumberjackConsoleToken);
  config = inject(lumberjackConsoleDriverConfigToken);
  /**
   * Output console error.
   *
   * @param param0 The log and its text representation.
   */
  logCritical({
    formattedLog,
    log: {
      payload
    }
  }) {
    this.#console.error(formattedLog, payload);
  }
  /**
   * Output console debug message.
   *
   * @param param0 The log and its text representation.
   */
  logDebug({
    formattedLog,
    log: {
      payload
    }
  }) {
    this.#console.debug(formattedLog, payload);
  }
  /**
   * Output console error.
   *
   * @param param0 The log and its text representation.
   */
  logError({
    formattedLog,
    log: {
      payload
    }
  }) {
    this.#console.error(formattedLog, payload);
  }
  /**
   * Output console info.
   *
   * @param param0 The log and its text representation.
   */
  logInfo({
    formattedLog,
    log: {
      payload
    }
  }) {
    this.#console.info(formattedLog, payload);
  }
  /**
   * Output console trace.
   *
   * @param param0 The log and its text representation.
   */
  logTrace({
    formattedLog,
    log: {
      payload
    }
  }) {
    this.#console.trace(formattedLog, payload);
  }
  /**
   * Output console warning.
   *
   * @param param0 The log and its text representation.
   */
  logWarning({
    formattedLog,
    log: {
      payload
    }
  }) {
    this.#console.warn(formattedLog, payload);
  }
  static ɵfac = function LumberjackConsoleDriver_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LumberjackConsoleDriver)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: LumberjackConsoleDriver,
    factory: LumberjackConsoleDriver.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LumberjackConsoleDriver, [{
    type: Injectable
  }], null, null);
})();

/**
 * Returns the [dependency-injection providers](https://angular.io/guide/glossary#provider)
 *
 * for the `LumberjackConsoleDriver` and its `LumberjackConsoleDriverConfig`.
 * @usageNotes
 *
 * The function is useful when you want to bootstrap an application using
 * the `bootstrapApplication` function and want to make available the `LumberjackConsoleDriver` providers.
 *
 * ```typescript
 * bootstrapApplication(RootComponent, {
 *   providers: [
 *    provideLumberjack({...}),
 *    provideLumberjackConsoleDriver({...})
 *   ]
 * });
 * ```
 *
 * @publicApi
 */
function provideLumberjackConsoleDriver(config = {}) {
  return [{
    provide: lumberjackConsoleDriverConfigToken,
    deps: [lumberjackLogDriverConfigToken],
    useFactory: logDriverConfig => ({
      ...logDriverConfig,
      identifier: LumberjackConsoleDriver.driverIdentifier,
      ...config
    })
  }, {
    provide: lumberjackLogDriverToken,
    useClass: LumberjackConsoleDriver,
    multi: true
  }];
}

/*
 * Public API surface of @ngworker/lumberjack/console-driver
 */
// Configuration

/**
 * Generated bundle index. Do not edit.
 */

export { LumberjackConsoleDriver, lumberjackConsoleToken, provideLumberjackConsoleDriver };
